import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"
import { Link } from "gatsby"

const ThankYouPage = () => (
	<Layout>
		<SEO title="Thank You Page" description="continuing education" />
		<div class="page-headline">
			<div class="container">
				<div class="section-heading text-center">
					<h1 />
				</div>
			</div>
		</div>
		<div class="page-content">
			<div class="container">
				<div className="intro mb-4 ">
					<div className="row">
						<div className="col-md-9 mx-auto text-center">
							<h2 className="text-primary">
								<span className="text-orange">Thank You&nbsp;</span>For Trusting
								Us
							</h2>
							<h4 className="text-muted">
								{" "}
								<strong>
									We will respond to your email as soon as possible. We look
									forward to working with you in the future.
								</strong>{" "}
							</h4>
							<hr />
							<p className="d-flex flex-column flex-sm-row justify-content-center mt-5">
								<Link
									className="btn btn-primary shadow mr-sm-3 mb-3 mb-sm-0 arrow"
									to="/"
									target="_blank"
									rel="noopener noreferrer"
								>
									BACK TO HOMEPAGE
								</Link>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
)

export default ThankYouPage
